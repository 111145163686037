<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.5456 0.5L8 7.04564L1.45436 0.5L0 1.95436L6.54564 8.5L0 15.0456L1.45436 16.5L8 9.95436L14.5456 16.5L16 15.0456L9.45436 8.5L16 1.95436L14.5456 0.5Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
